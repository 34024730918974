<template>
  <button class="button">
    {{ text }} <i :class="icon" aria-hidden="true"></i>
  </button>
</template>

<script>
export default {
  props: {
    text: String,
    icon: String,
  },
};
</script>

<style scoped>
.button {
  margin-top: 10px;
  position: relative;
  padding: 13px 28px 11px 28px;
  font-size: 20px;
  text-transform: uppercase;
  color: #141414;
  transition: all 0.4s;
  font-family: Montserrat, sans-serif;
  background: #fc0;
  border-radius: 8px;
  border: none;
  width: 100%;
  text-align: left;
}

.button:hover {
  color: #fff;
  background: #141414;
}

.button:hover .svg-inline--fa {
  right: 15px;
}

/*  Die "i" werden ersetzt, 
    deshalb dieser Quatsch */
.svg-inline--fa {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  font-size: 20px;
  transition: all 0.4s;
}

/* media smartphone */
@media (max-width: 768px) {
  .button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}
</style>
