<template>
  <div>
    <ImageHeader
      :text="loginTitle"
      imageUrl="http://h-team.torbara.com/images/soccer/page-header-bg.jpg"
    />
    <div class="container">
      <section class="login-form">
        <div class="col-lg-12">
          <h4>Einloggen</h4>
          <transition name="fade">
            <InfoBox
              v-show="errorStatus.text !== ''"
              :text="errorStatus.text"
              linkText=""
              link=""
              :messageType="errorStatus.level"
              isClosable="no"
            />
          </transition>
        </div>

        <div v-show="isLoading"><LoadingComponent /></div>

        <div v-show="!isLoading" class="col-lg-12 form-container">
          <b-form @submit="onSubmit">
            <div class="row">
              <div class="col-md-12">
                <div class="item">
                  <label class="d-block">
                    <span>Loginname <i>*</i></span>
                    <input
                      type="text"
                      @keypress="status = ''"
                      v-model="email"
                      name="Loginname"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="item">
                  <label class="d-block">
                    <span>Passwort <i>*</i></span>
                    <input
                      type="password"
                      @keypress="status = ''"
                      v-model="password"
                      name="password"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <Button text="Einloggen" icon="fas fa-sign-in-alt" />
            </div>
          </b-form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ImageHeader from "@/app/components/image-header.vue";
import InfoBox from "@/app/components/info-box.vue";
import Button from "@/app/components/button.vue";
import LoadingComponent from "@/app/components/loading.vue";

const { DEV_MODE } = require("@/store/settings");

export default {
  data() {
    return {
      errorStatus: {
        text: "",
        level: "",
      },
      devmode: DEV_MODE,
      email: "",
      password: "",
    };
  },
  components: {
    ImageHeader,
    InfoBox,
    LoadingComponent,
    Button,
  },
  computed: {
    loginTitle() {
      return this.devmode == "true" ? "LOGIN | DEVELOPMENT" : "Login";
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    checkData() {
      if (!this.email || this.email === "") {
        this.errorStatus.text = "Bitte Email eingeben ";
        return false;
      }

      if (!this.password || this.password === "") {
        this.errorStatus.text = "Bitte Passwort eingeben";
        return false;
      }
      return true;
    },

    async onSubmit(evt) {
      evt.preventDefault();

      if (!this.checkData()) return false;

      await this.$store.dispatch("login", {
        email: this.email,
        password: this.password,
      });

      if (this.$store.getters.user.isLoggedIn) {
        this.$router.push("/spiele");
        return;
      }

      this.errorStatus = this.$store.getters.error;
    },
  },
};
</script>

<style scoped>
.info-container {
  margin: 10px;
}
</style>
