<template>
  <div v-show="shown" class="col-lg-12">
    <div :class="'alert infobox ' + getCorrectColor" role="alert">
      <button
        v-show="isClosable == 'yes'"
        @click="hide()"
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <i class="fas fa-times close-btn" aria-hidden="true"></i>
      </button>
      {{ text }} <a v-show="link !== ''" :href="link">{{ linkText }} </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shown: true,
    };
  },
  methods: {
    hide() {
      this.shown = false;
    },
  },
  computed: {
    getCorrectColor() {
      let cl = "";
      switch (this.messageType) {
        case "info":
          cl = "color-info";
          break;

        case "warning":
          cl = "color-warning";
          break;

        case "danger":
          cl = "color-danger";
          break;

        case "success":
          cl = "color-success";
          break;

        default:
          cl = "color-warning";
          break;
      }

      return cl;
    },
  },
  props: {
    text: String,
    link: String,
    linkText: String,
    messageType: String,
    isClosable: Boolean,
  },
};
</script>

<style scoped>
.color-info {
  border: solid #8989d2;
}

.color-warning {
  border: solid #fc0;
}

.color-danger {
  border: solid maroon;
}

.color-success {
  border: solid green;
}

.infobox {
  transition: opacity 0.5s;

  padding: 21px 70px 22px 30px;
  position: relative;
  background: #fcfcfc;
  border-radius: 0;
  overflow-x: hidden;
  border-width: 0 0 0 5px;
}
button.close {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}
.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}
.infobox .close {
  width: 60px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 1;
}
.infobox .close:before {
  content: "";
  background: #f0f0f0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -40px;
  transform: skew(-6deg);
  transition: all 0.4s;
}
.close:hover:before {
  background: #fc0;
}
.close-btn {
  position: relative;
  font-size: 12px;
  color: #666;
  line-height: 32px;
}
.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
}
</style>
