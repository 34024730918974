<template>
  <section class="image-header" :style="imageStyle">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="info">
            <div class="wrap">
              <h1>{{ text }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    text: String,
    imageUrl: String,
  },
  computed: {
    imageStyle() {
      return `background: url(${this.imageUrl}) no-repeat right;  background-size: cover;`;
    },
  },
};
</script>

<style scoped>
h1 {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
}
.image-header {
  min-height: 300px;
  overflow: hidden;
  clear: both;
}
.image-header .info:before {
  content: "";
  margin-bottom: -100%;
  background-color: rgba(255, 204, 0, 0.7);
  top: 0;
  bottom: 0;
  right: -30px;
  left: -1000%;
  position: absolute;
  transform: skew(-10deg);
}
.image-header .info {
  padding-top: 136px;
  padding-bottom: 55px;
  position: relative;
}
.image-header .info .wrap {
  position: relative;
}
.image-header h1 {
  padding-top: 5px;
  margin-top: 0;
  color: #141414;
}
</style>
